import * as React from "react"
import getClass from "../../utils/get-class"
import {
  menuOpen,
  menuIcon,
  lineOne,
  lineTwo,
  lineThree,
} from "./menu-icon.module.css"

export default function MenuIcon({ isOpen }) {
  return (
    <svg
      className={getClass(isOpen && menuOpen, menuIcon)}
      viewBox="0 0 100 100"
    >
      <path className={lineOne} d="M10 20 L90 20" />
      <path className={lineTwo} d="M10 50 L90 50" />
      <path className={lineThree} d="M10 80 L90 80" />
    </svg>
  )
}
