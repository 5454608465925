import * as React from "react"
import getClass from "../../utils/get-class"
import NavigationItem from "./navigation-item"
import { navigation, navShowing, navigationList } from "./navigation.module.css"

export default function Navigation({ buildItems, className, showing }) {
  return (
    <nav className={getClass(className, navigation, showing && navShowing)}>
      <ul className={navigationList}>{buildItems(NavigationItem)}</ul>
    </nav>
  )
}
