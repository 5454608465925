import { useLayoutEffect, useRef } from "react"
import { useLocation } from "@gatsbyjs/reach-router"

/**
 * Helper which resets the scroll to 0,0 on a scroll container whenever
 * the app navigates.
 */
export default function useResetScrollOnNavigate() {
  const ref = useRef()
  const { key } = useLocation()

  // in order to run seamlessly after re-render on route change
  // use the layout effect hook - which runs sync with dom updates
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [key])

  return { ref }
}
