import * as React from "react"
import { Link } from "gatsby"
import ResponsiveLayout from "../components/layouts/responsive-layout"
import { Consumer as ResponsiveLayoutContextConsumer } from "../components/layouts/responsive-layout-context"
import useResetScroll from "../hooks/use-reset-scroll-on-navigate"
import "../styles/global.css"
import {
  homePageLink,
  appHeading,
  footerCopyright,
  layout,
} from "./index.module.css"

export const siteLinks = {
  "/contact-us": "contact us",
  "/our-games": "our games",
}

export default function Layout({ children }) {
  const year = new Date().getFullYear()

  // reset scroll after any navigation within the app
  const { ref: scrollAreaRef } = useResetScroll()

  const hideNavigationOnLinkClicked = (navShowing, setNavShowing) => () => {
    if (navShowing) {
      setNavShowing(false)
    }
  }

  const header = (
    <ResponsiveLayoutContextConsumer>
      {({ navShowing, setNavShowing }) => (
        <Link
          className={homePageLink}
          to="/"
          aria-label="go to home page"
          onClick={hideNavigationOnLinkClicked(navShowing, setNavShowing)}
        >
          <h1 className={appHeading}>sqog games</h1>
        </Link>
      )}
    </ResponsiveLayoutContextConsumer>
  )
  const footer = <span className={footerCopyright}>© {year} sqog</span>

  return (
    <ResponsiveLayout
      className={layout}
      headerContent={header}
      buildNavigationItems={(navShowing, setNavShowing) => NavItem =>
        Object.entries(siteLinks)
          .sort(([, titleA], [, titleB]) => titleA.localeCompare(titleB))
          .map(([url, linkText]) => (
            <NavItem
              to={url}
              key={linkText}
              onClick={hideNavigationOnLinkClicked(navShowing, setNavShowing)}
            >
              {linkText}
            </NavItem>
          ))}
      footerContent={footer}
      ref={scrollAreaRef}
    >
      {children}
    </ResponsiveLayout>
  )
}
