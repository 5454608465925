exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-games-pling-privacy-js": () => import("./../../../src/pages/games/pling/privacy.js" /* webpackChunkName: "component---src-pages-games-pling-privacy-js" */),
  "component---src-pages-games-slydr-privacy-js": () => import("./../../../src/pages/games/slydr/privacy.js" /* webpackChunkName: "component---src-pages-games-slydr-privacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-games-js": () => import("./../../../src/pages/our-games.js" /* webpackChunkName: "component---src-pages-our-games-js" */)
}

