import * as React from "react"
import getClass from "../../utils/get-class"
import Navigation from "../navigation/navigation"
import NavigationToggle from "../navigation/navigation-toggle"
import { Provider as ResponsiveLayoutContextProvider } from "./responsive-layout-context"
import {
  responsiveLayout,
  header,
  headerContent as headerContentClass,
  navToggle,
  nav,
  main,
  footer,
} from "./responsive-layout.module.css"
const { useState } = React

export default React.forwardRef(function ResponsiveLayout(
  {
    buildNavigationItems,
    children: mainContent,
    className,
    footerContent,
    headerContent,
  },
  ref
) {
  const [navShowing, setNavShowing] = useState(false)

  return (
    <ResponsiveLayoutContextProvider
      value={{
        navShowing,
        setNavShowing,
      }}
    >
      <div className={getClass(responsiveLayout, className)} ref={ref}>
        <header className={header}>
          <div className={headerContentClass}>{headerContent}</div>
          <NavigationToggle
            className={navToggle}
            isOpen={navShowing}
            onToggle={() => setNavShowing(!navShowing)}
          />
        </header>
        <Navigation
          className={nav}
          buildItems={buildNavigationItems(navShowing, setNavShowing)}
          showing={navShowing}
        />
        <main className={main}>{mainContent}</main>
        <footer className={footer}>{footerContent}</footer>
      </div>
    </ResponsiveLayoutContextProvider>
  )
})
