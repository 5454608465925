import * as React from "react"
import { Link } from "gatsby"
import getClass from "../../utils/get-class"
import {
  navigationItem,
  activeLink,
  navigationLink,
} from "./navigation-item.module.css"

export default function NavigationItem(props) {
  const listItemProps = { ...props }
  delete listItemProps.children
  delete listItemProps.href
  delete listItemProps.linkClassName
  delete listItemProps.onClick

  return (
    <li
      {...listItemProps}
      className={getClass(props.className, navigationItem)}
    >
      <Link
        activeClassName={activeLink}
        className={getClass(props.linkClassName, navigationLink)}
        to={props.to}
        onClick={props.onClick}
      >
        {props.children}
      </Link>
    </li>
  )
}
