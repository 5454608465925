import * as React from "react"
import getClass from "../../utils/get-class"
import MenuIcon from "./menu-icon"
import { navigationToggle } from "./navigation-toggle.module.css"

export default function NavigationToggle({ className, isOpen, onToggle }) {
  return (
    <button
      className={getClass(className, navigationToggle)}
      aria-label="Navigation Toggle"
      onClick={onToggle}
    >
      <MenuIcon isOpen={isOpen} />
    </button>
  )
}
